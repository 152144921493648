<template>
  <div class="bg-light pt-150" style="min-height:875px">
    <div class="container">
      <div class="col">
        <div class="row">
          <div class="col-lg-4" v-if="!isMobile">
            <img src="https://ui.hireclap.com/img/access1.png" class="simgb" />
          </div>
          <div class="col-lg-4 p-5 login-bg mt-5 mb-5" style="box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.52)">
            <b-alert style="top: -93px;" variant="success" :show="showAlert" v-if="!isMobile" dismissible><P class="mb-0 text-center" style="font-size:13px;opacity:0.7"><small>Successfully registered!</small></P><P class="mb-0 text-center" style="font-size:13px;opacity:0.9;" v-if="this.$route.params.email_status"><small>Verify your email & login</small></P><P class="mb-0 text-center" v-if="this.$route.params.email_status" style="font-size:12px;opacity:0.6;"><small>Verification link sent to your email id.Check inbox / spam folder </small></P></b-alert>
            <b-alert variant="success" :show="showAlert" style="top:-50px;" v-if="isMobile" dismissible><P class="mb-0 text-center" style="font-size:13px;opacity:0.7"><small>Successfully registered!</small></P><P class="mb-0 text-center" style="font-size:13px;opacity:0.9;" v-if="this.$route.params.email_status"><small>Verify your email & login</small></P><P class="mb-0 text-center" v-if="this.$route.params.email_status" style="font-size:12px;opacity:0.6;"><small>Verification link sent to your email id.Check inbox / spam folder </small></P></b-alert>
            <b-alert style="top: -93px;" variant="success" :show="tag_institute" v-if="!isMobile" dismissible><P class="mb-0 text-center" style="font-size:13px;opacity:0.7"><small>Already registered</small></P><P class="mb-1 text-center" style="font-size:13px;opacity:0.9;" v-if="tag_institute"><small>Login to add training details</small></P></b-alert>
            <b-alert variant="success" :show="tag_institute" style="top:-50px;" v-if="isMobile" dismissible><P class="mb-0 text-center" style="font-size:13px;opacity:0.7"><small>Already registered</small></P><P class="mb-1 text-center" style="font-size:13px;opacity:0.9;" v-if="tag_institute"><small>Login to add training details</small></P></b-alert>
            <b-alert style="top: -93px;" variant="success" :show="tag_college" v-if="!isMobile" dismissible><P class="mb-0 text-center" style="font-size:13px;opacity:0.7"><small>Already registered</small></P><P class="mb-1 text-center" style="font-size:13px;opacity:0.9;" v-if="tag_college"><small>Login to tag college</small></P></b-alert>
            <b-alert variant="success" :show="tag_college" style="top:-50px;" v-if="isMobile" dismissible><P class="mb-0 text-center" style="font-size:13px;opacity:0.7"><small>Already registered</small></P><P class="mb-1 text-center" style="font-size:13px;opacity:0.9;" v-if="tag_college"><small>Login to tag college</small></P></b-alert>
           
            <h1 class="text-primary mb-7 text-center" style="font-size:1.5rem !important;">
              <strong>Jobseeker login</strong>
            </h1>
            <div class="success-feedback mb-5" v-if="reset_success">Password has been changed. Please login with your new password</div>
            <v-form ref="login_form" v-model="valid" lazy-validation>
              <div label-for="input-1">
                <v-text-field type="text" v-model="login.email" :rules="[v => !!v || 'Enter valid email']" :error-messages="email_error" @keydown.space.prevent  outlined><template #label>Email<span class="red--text"><strong>* </strong></span></template></v-text-field>
              </div>
              <div label-for="login-password" class="mt-2">
                <v-text-field :append-icon="show1 ? $icons.eye : $icons.eyeOff" :type="show1 ? 'text' : 'password'" :error-messages="password_error" @click:append="show1 = !show1" v-model="login.password" :rules="[v => !!v || 'Enter valid password']"  outlined><template #label>Password<span class="red--text"><strong>* </strong></span></template></v-text-field>  
              </div>
              <div class="error-feedback" v-if="auth_error">{{auth_msg}}</div>
              <div class="error-feedback" v-if="notverified_error">Email has not been verified. <span @click="sendVerification()" style="color:#007bff;cursor:pointer">Click here to send verification</span></div>
              <div class="success-feedback" v-if="success_msg">{{success_msg}}</div>
            
               <b-form-group label-for="check-sign">
                <div class="d-flex justify-content-between align-items-baseline">
                  <a class="" href="/jobseeker/request_password" target="_blank"> Forgot password?</a>
                  <a href="mailto:recruit@hireclap.com" class="ml-5" v-if="write_to_admin">Write to admin</a>
                </div>
              </b-form-group>
              <div class="text-center mt-5">
                <b-button v-if="!loading" @click="userlogin()" class="mr-3 text-center w-100" style="color:#fff !important" variant="primary">Login</b-button>
                <b-button v-if="loading" class="mr-3 text-center w-100" style="color:#fff !important" variant="primary">...</b-button>
              </div>
            </v-form>
            <div class="col-lg-12 text-secondary p-0 text-center mt-3">Not a member?<a href="/jobseeker/register" class="flink text-decoration-none"><strong> Create an account </strong></a></div>
            <div class="col-lg-12 text-center pr-0 pl-0"><small class="mb-0"><a href="/privacy" target="_blank" class="flink text-decoration-none lgtTxt">Privacy Policy </a>|<a href="/terms" target="_blank" class="flink text-decoration-none lgtTxt"> Terms &amp; Conditions</a></small><hr><p class="text-center text-secondary m-auto">Are you an employer?<a href="/recruit/register" class="text-decoration-none"> Register free</a></p></div>
          </div>
          <div class="col-lg-4" v-if="!isMobile">
            <img src="https://ui.hireclap.com/img/res1.png" class="simga hideimg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import validation from '../libraries/validFormat' ;
import Actions from '../libraries/apiActions.js';
import '../assets/hire.css';
var breadcrumbJson = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": window.location.origin
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "Login",
        "item": window.location.href
      }]
    }
export default {
  props:['visume'],
  data() {
    return {
      validate: new validation(),
      action: new Actions(),
      login:{
        email:null,
        password:null,
      },
      errors: [],
      notVerifiedError: false,
      notverified_error:false,
      auth_error:false,
      can_login:0,
      success_msg: false,
      showAlert:false,
      showDismissibleAlert:false,
      reset_success: false,
      write_to_admin:false,
      isMobile:false,
      show1:false,
      valid:true,
      email_error:null,
      password_error:null,
      loading:false,
      tag_institute: false,
      tag_college: false,
      institute: null
    };
  },
  metaInfo(){
    return {
      script: [
      {type: 'application/ld+json', json: breadcrumbJson},
    ]
    }
  },
  beforeMount() {
    window.scrollTo(0, 0);
    this.reset_success = this.$route.query.reset_success || false;
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.isMobile = true;
    }
    if(Object.keys(this.$route.params).length){
      this.showDismissibleAlert = true;
      if(this.$route.params.institute){
        this.institute = this.$route.params.institute;
        this.tag_institute = true;
      }
      if(this.$route.params.college){
        this.college = this.$route.params.college;
        this.tag_college = true;
      }
      console.log(this.$route.params);
      // if(this.$route.params.visume == 'skipped'){
      //   this.showDismissibleAlert = true;
      // }else{
      //   this.showDismissibleAlert = false;
      // }

      // if(this.$route.params.visume == 'uploaded'){
        this.showAlert = true;
      // }else{
      //   this.showAlert = false;
      // }
    }else{
      this.showDismissibleAlert = false;
    }
  },
  methods:{
      sendVerification: function() {
        this.notverified_error = false;
        if(this.tag_college && this.college){
          this.login.college = this.college;
        }
        this.action.sendVerification(this.login).then((res) => {
          if(res.status) {
           this.success_msg = "Email verification link send. Check your email & verify"
          } else {
            this.auth_error = true;
            this.auth_msg = res.error
          }
          }).catch((err) => {
          if(err){
            console.log(err);
            this.auth_error = true;
            this.auth_msg = err.error;
          }
        });
    },
    userlogin: function () {
        // e.preventDefault();
        let src = this.$route.query.src;
        let redirect_from = this.$route.query.redirect_from;
        this.auth_error = false;
        this.success_msg = false;
        this.auth_msg = '';
        this.notverified_error = false;
        this.email_error = '';
        this.password_error = '';
        this.valid = this.$refs.login_form.validate();

        if(this.valid == true){
          this.loading = true;
          if(src) {
            this.login['src'] = src;
          }
          new Actions()
            .seekerLogin(this.login)
            .then((res) => {
              this.loading=false;
              if(res.length&&res[0].status == 1) {
                // this.notverified_error = true;
                if(res[0].message == 'Email not registered with us'){
                  this.email_error = "Email not registered with us";
                }
              } else if(res.status == 2){
                  this.password_error = "Password incorrect";
              }else if(res.email || res.password){
                this.auth_error = true;
                this.auth_msg = "Invalid Email or Password";
              }else if(res.message == 'Email not verified'){
                if(this.tag_college){
                  this.addCollege();
                }
               this.notverified_error = true;
              }else{
                const seeker = {token: res.token,name:res.name,visume_path:res.visume_path,resume_path:res.resume_path,id:res.id,alert:(!res.resume_path||!res.visume_path)?'yes':'no'}
                this.$cookies.set("hire-seeker", seeker);
                this.user = seeker;
                window.scrollTo(0, 0);
                if(this.tag_institute){
                  this.addTrainingDetails();
                }
                if(this.tag_college){
                  this.addCollege();
                }
                if(this.$route.query.redirect_from) {
                  console.log(this.$route.query.redirect_from)
                  // window.location.href = process.env.VUE_APP_URL_WS+redirect_from;
                  window.location.href = this.$route.query.redirect_from;
                } else if(src === 'seeker_job_description'){
                  window.location.href = '/jobs/'+redirect_from;
                }else{
                  this.$router.replace('/myprofile');
                  location.reload();
                }
              }
            })
            .catch((err) => {
              if (err) {
                console.log(err);
                this.loading= false;
                if(window.navigator.onLine){
                  this.auth_msg = "System error! Please try again.";
                }else{
                  this.auth_msg = "Network error! Please check your internet connection.";
                }
              }
            });
        }else{
          this.loading= false;
          this.auth_error = true;
          this.auth_msg = "Required fields are empty!";
        }
    },
    addTrainingDetails: async function(){
      let token = this.$cookies.get('hire-seeker').token
      await new Actions()
            .addUserTrainingDetails({
              institute: this.institute
            }, token).then(res => console.log(res));
    },
    addCollege: async function(){
      let token = this.$cookies.get('hire-seeker').token
      await new Actions()
            .tagUserCollege({
              college: this.college
            }, token).then(res => console.log(res));
    }
  }

}
</script>

<style>
.error--text fieldset{
  border: 2px solid #ff5252 !important;
}
.login-bg{background: #fff url(https://d22r5huxahsjvt.cloudfront.net/Assets/images/register-bg.png) no-repeat -62px top;}.simgb{width: 350px;padding-top: 105px;}.simga{width: 455px;padding-top: 162px;}.pt-150 {padding-top:150px;}.container{max-width: 1140px !important;}@media only screen and (max-width: 640px){.m-hide {display: none;}.pt-150 {padding-top:100px;}}.error-feedback{margin-bottom: 15px;width: 100%;font-size: 80%;color: #dc3545;}.lgtTxt{color: #a4a5aa !important;}.alert{position: absolute;width: 395px;margin-left: -4rem;}
</style>